/**
 * Created by osirvent on 30/06/2016.
 */
'user strict';
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('annexa.tram', {
                abstact: true,
                url: '/tram',
                redirectTo: 'annexa.tram.pending',
                views: {
                    "": {
                        controller: 'TramController',
                        templateUrl: './views/layout/tram.html'
                    }
                },
                data: {
                    title: 'global.literals.trams',
                    displayName: 'global.literals.trams',
                    authenticate: true
                },
                resolve: {
                    getDocumentLists: ['DccumentsFactory', function (DccumentsFactory) {
                        return DccumentsFactory.getDocumentLists();
                    }],
                    getTerritorytLists: ['TerritoryFactory', function (TerritoryFactory) {
                        return TerritoryFactory.initializeLists();
                    }]
                }
            })
            .state('annexa.tram.start', {
                url: '/start',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramStartController',
                        templateUrl: './views/layout/start.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.start.title',
                    permissions: {
                        only: 'start_dossier'
                    }
                }
            })
            .state('annexa.tram.pending',{
                url: '/pending/:type',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramPendingController',
                        templateUrl: './views/layout/pending.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.pending.title',
                    permissions: {
                        only: 'process_dossier'
                    }
                }
            })
            .state('annexa.tram.view', {
                url: '/view/:type',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramViewController',
                        templateUrl: './views/layout/view.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.view.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier']
                    }
                }
            })
            .state('annexa.tram.responsable', {
                url: '/responsable/:type',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramResponsableController',
                        templateUrl: './views/layout/responsable.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.responsable.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier']
                    }
                }
            })
            .state('annexa.tram.newdossier', {
                url: '/newdossier/:procedure',
                params: {
                    register: undefined,
                    diligence: undefined
                },
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramNewDossierController',
                        templateUrl: './views/layout/newdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.new.dossier.title',
                    permissions: {
                        only: 'start_dossier'
                    }
                },
                resolve: {
                    procedure: ['$q', '$state', '$stateParams', 'TramFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramFactory, $timeout, $rootScope) {
                        if($stateParams.procedure) {
                            return TramFactory.getProcedure($stateParams.procedure, $rootScope.app.entity, $stateParams.register, $stateParams.diligence);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.pending'); });
                            return $q.reject("No procedure");
                        }
                    }]
                }
            })
            .state('annexa.tram.pending.viewdossier', {
                url: '/viewdossier/:dossier/:dossierTransaction',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramViewDossierController',
                        templateUrl: './views/layout/viewdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    permissions: {
                        only: 'process_dossier'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramFactory.getDossier($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.pending'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.pending'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }]
                }
            })
            .state('annexa.tram.view.viewdossier', {
                url: '/viewdossier/:dossier',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramViewDossierViewController',
                        templateUrl: './views/layout/viewdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier']
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramFactory.getDossier($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            })
            .state('annexa.tram.responsable.viewdossier', {
                url: '/viewdossier/:dossier',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramViewDossierController',
                        templateUrl: './views/layout/viewdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier']
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramFactory.getDossier($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            });
    }]);