/**
 * Created by osirvent on 22/07/2016.
 */

    //region Models

var dossier = function(procedure,subject,observations, classification, archiveClassification, tramitationType) {
    this.dossierNumber='pending';
    this.createdNumber = 0;
    this.procedure = procedure;
    this.date = new Date();
    this.subject = subject;
    this.observations = observations;
    this.classification = new procedureClassification(classification);
    this.archiveClassification = archiveClassification;
    this.maxDays = procedure.maxDays;
    if(tramitationType && tramitationType.id) {
        this.dossierTramitationType = {id: tramitationType.id};
    }else if(tramitationType){
        this.dossierTramitationType = {id: tramitationType};
    }
};

var procedureClassification = function(id) {
    this.id = id;
}

    //endregion

    //region Columnes Tabledata

var TransactionsCounterColumn = function() {
    this.getTitle = function () {
        return '';
    }

    this.getRender = function (data, type, full, meta) {
        var content = '<span class="text-nowrap">';
        content += '<i class="fa fa-unlock text-warn" title="{{\'global.tram.literals.open_transaction\' | translate}}"></i> ' + ((full.dossier && full.dossier.actualDossierTransactions)?full.dossier.actualDossierTransactions:((full.actualDossierTransactions)?full.actualDossierTransactions:0));
        content += '<i class="fa fa-lock text-success m-l-xs" title="{{\'global.tram.literals.ended_transaction\' | translate}}"></i> ' + ((full.dossier && full.dossier.endDossierTransactions)?full.dossier.endDossierTransactions:((full.endDossierTransactions)?full.endDossierTransactions:0));

        content += '</span>';

        return content;
    }
}

var RelatedDossiersColumn = function () {
    this.getTitle = function() {
        return '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data.length != 0) {
            content = '<i class="fa fa-link" title="{{\'global.tram.literals.related_dossier\' | translate}}"><span class="label rounded label-sm amber font-default">' + data.length + '</span></i>';
        }
        return content;
    }
}

var DossierStatusColumn = function (filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function () {
        return filter('translate')(title);
    }

    this.getRender = function (data, type, full, meta) {
        var classStatus = '';

        switch (data) {
            case 'IN_PROGRESS':
                classStatus = 'warn';
                break;
            case 'FINISHED':
                classStatus = 'success';
                break;
            case 'CANCELLED':
                classStatus = 'danger';
                break;
        }

        var content = '';

        content += '<strong>';
        content += '    <span class="inline label rounded ' + classStatus + '">' + filter('translate')(data) + '</span>';
        content += '</strong>';

        return content;
    }
}

var DossierResponsibleColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var identifier = '';
        var name = '';
        var content = '';
        
        if(full.responsibleUser){
            identifier = full.responsibleUser.identifier;
            name = full.responsibleUser.name + ' ' + full.responsibleUser.surename1;
            if(full.responsibleUser.surename2){
                name = name  + ' ' + full.responsibleUser.surename2 ;
            }
            
            content += '</span><a href ui-sref="annexa.profile({idUser: \'' + identifier + '\'})" class="text-info linkNoStyle tresPuntets" title="'+name+'">'+name+'</a>';
        }        

        return content;
    }
}

var TransactionCreatorColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var identifier = '';
        var name = '';        
        var content = filter('date')(new Date(full.startDate), 'dd/MM/yyyy');
        
        if(full.creatorUser) {        	
        	identifier = full.creatorUser.identifier;
        	name = full.creatorUser.name + ' ' + full.creatorUser.surename1;
            if(full.creatorUser.surename2){
                name = name  + ' ' + full.creatorUser.surename2 ;
            }
        }

        content += '<br/><span>'+ filter('translate')('global.literals.created_for') +': </span><a href ui-sref="annexa.profile({idUser: \'' + identifier + '\'})" class="text-info linkNoStyle tresPuntets" title="'+name+'">'+name+'</a>';

        return content;
    }
}

var TransactionResponsibleColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var identifier = '';
        var name = '';
        var content = '';
        
        if(full.responsibleUser){
        	identifier = full.responsibleUser.identifier;
        	name = full.responsibleUser.name + ' ' + full.responsibleUser.surename1;
            if(full.responsibleUser.surename2){
                name = name  + ' ' + full.responsibleUser.surename2 ;
            }
            
            content += '</span><a href ui-sref="annexa.profile({idUser: \'' + identifier + '\'})" class="text-info linkNoStyle tresPuntets" title="'+name+'">'+name+'</a>';
        }        

        return content;
    }
}

var TransactionDossierResponsibleColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var identifier = '';
        var name = '';
        var content = '';
        
        if(full.dossier && full.dossier.responsibleUser){
        	identifier = full.dossier.responsibleUser.identifier;
        	name = full.dossier.responsibleUser.name + ' ' + full.dossier.responsibleUser.surename1;
            if(full.dossier.responsibleUser.surename2){
                name = name  + ' ' + full.dossier.responsibleUser.surename2 ;
            }
            
            content += '</span><a href ui-sref="annexa.profile({idUser: \'' + identifier + '\'})" class="text-info linkNoStyle tresPuntets" title="'+name+'">'+name+'</a>';
        }        

        return content;
    }
}

var TransactionResponsibleProfileColumn = function(filter, title, language) {
    this.filter = filter;
    this.title = title;
    this.language = language;

    this.getColumn = function() {
        return language;
    }

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if (full.processProfiles) {
        	var threeProfiles = '';
        	var allProfiles = '';
        	var i = 0;
        	$linq(full.processProfiles).foreach(function (x) { 
        		if(i < 3){
        			threeProfiles += x.profile[language] + ', ';
        		}
        		allProfiles  += x.profile[language] + ', ';
        		i++;
            });
        	allProfiles = allProfiles.slice(0, -2);
        	if(i < 3){
        		threeProfiles = threeProfiles.slice(0, -2);
        	}else{
        		threeProfiles += '...';
        	}
        	content = '<span title="'+allProfiles+'">'+threeProfiles+'<span>'; 
        }

        return content;
    }
}

var RemaingDaysColumn = function(filter, title, helper) {
    this.filter = filter;
    this.title = title;
    this.helper = helper;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        //TODO: Falta el tema del justificant del retard

        var content = '';
        if(data) {
            content += '<span class="label wi-175em '+helper.getWorkingDaysClass(data)+'">';
            if (data > 5) {
            }
            content += data + "</span>";
        }
        return content;
    }
}

var TransactionNameColumn = function(filter, title, language) {
    this.filter = filter;
    this.title = title;
    this.language = language;

    this.getColumn = function() {
        return language;
    }

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data){
            content =  data;
        }
        if(full && full.transactionType && full.transactionType[language]){
            content += ((data)?'<br/>':'')+full.transactionType[language];
        }
        return content;
    }
}

    //endregion




    //endregion